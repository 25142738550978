<template>
  <div
    class="cart-summary"
    :class="[
      { 'empty' : storeCart.items && storeCart.items.length === 0 }
    ]"
  >
    <!-- cart:back+headline START -->
    <router-link
      class="back back-with-arrow"
      :to="'/cart'"
      v-html="$t('terminal.cart.buttons.back')"
      v-if="storeCart.items && storeCart.items.length > 0"
    ></router-link>
     <h1
      v-if="storeCart.items && storeCart.items.length > 0"
      v-html="$t('terminal.cart.summary.headline')"
      class="main-headline"
    ></h1>
    <!-- cart:back+headline END -->

    <!-- cart:empty START <empty-cart
      v-if="storeCart.items && storeCart.items.length === 0"
    /> cart:empty END --> <!-- v-else PRODUCTS -->

    <!-- cart:products START -->
    <products
      :products="storeCart.items"
      :cart="cart"
      :increase="increase"
      :decrease="decrease"
      :priceFormat="priceFormat"
      v-if="storeCart.items && storeCart.items.length > 0"
    />
    <!-- cart:products END -->

    <!-- cart:actions START -->
    <actions-summary
      :products="storeCart"
      :categories="categories"
      :priceFormat="priceFormat"
      :resetStoreCart="resetStoreCart"
      :total="total"
      :sumVouchers="sumVouchers"
      :addProduct="addProduct"
      :done="done"
      :cardNr="userCardNr"
      :paymentCategoryID="paymentCategoryID"
      :box="box"
      v-if="storeCart.items && storeCart.items.length > 0"
    />
    <!-- cart:actions END -->

    <!-- approve:loading START -->
    <loading-default
      :loadingVisible="loadingVisible"
      :headline="loadingHeadline"
      :excerpt="loadingExcerpt"
      :showCloseAprove="showCloseAprove"
      :closeApprove="closeApproveLoading"
    />
    <!-- approve:loading END -->

    <!-- invoice:modal START -->
    <dialog-confirm
      :loadingVisible="loadingVisibleDialog"
      :headline="loadingHeadlineDialog"
      :excerpt="loadingExcerptDialog"
      :description="loadingDescriptionDialog"
      :open="openDeniedDialog"
      :close="closeDeniedDialog"
      :confirm="confirmDeniedDialog"
    />
    <!-- invoice:modal END -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'cart-summary',
  components: {
    // EmptyCart: () => import('../../components/cart/Empty.vue'),
    DialogConfirm: () => import('../../components/shared/dialog/ConfirmDenied.vue'),
    LoadingDefault: () => import('../../components/shared/loading/Default.vue'),
    Products: () => import('../../components/cart/ProductsSummary.vue'),
    ActionsSummary: () => import('../../components/cart/ActionsSummary.vue'),
  },
  data() {
    return {
      isScrolledMoreThan: false,
      lastScrollTop: 0,

      userCardNr: '1234567812345678',

      /**
       * Scan
       */
      qrCODE: '',

      /**
       * Box
       */
      box: null,
      boxToken: localStorage.getItem('boxToken') ? localStorage.getItem('boxToken') : 'undefined',
      boxLang: localStorage.getItem('boxLang') ? localStorage.getItem('boxLang') : 'undefined',

      paymentCategoryID: null,

      /**
       * Is age approved?
       */
      interval: null,

      /**
       * Loading
       */
      loadingVisible: true,
      loadingHeadline: this.$t('terminal.approve.summary.headline'),
      loadingExcerpt: this.$t('terminal.approve.summary.description'),
      showCloseAprove: true,

      /**
       * Dialog
       */
      loadingVisibleDialog: false,
      loadingHeadlineDialog: this.$t('terminal.denied.summary.headline'),
      loadingExcerptDialog: this.$t('terminal.denied.summary.excerpt'),
      loadingDescriptionDialog: this.$t('terminal.denied.summary.description'),
    };
  },
  async created() {
    // if boxToxen then fetch box
    if (this.boxToken !== 'undefined' && this.boxLang !== 'undefined') {
      this.box = await this.FETCH_BOX();

      if (this.box) {
        this.paymentCategoryID = this.$route.query.paymentCategoryID;

        this.IS_AGE_APPROVED();

        // get cart
        if (this.$store.getters.storeCart) {
          await this.setCart();
        }
      }
    }

    window.addEventListener('keydown', this.keyDownHandler);
    window.addEventListener('scroll', this.scrollHandler);
  },
  computed: {
    ...mapGetters({
      storeCart: 'storeCart',
    }),

    cart() {
      if (this.$store.getters.storeCart) {
        return this.$store.getters.storeCart.items;
      }
      return this.$store.state.storeCart;
    },

    total() {
      if (this.$store.getters.storeCart) {
        return this.$store.getters.storeCart.sumTotal;
      }
      return 0;
    },

    sumVouchers() {
      if (this.$store.getters.storeCart) {
        return this.$store.getters.storeCart.sumVouchers;
      }
      return 0;
    },

    categories() {
      if (this.$store.getters.categories) {
        return this.$store.getters.categories;
      }
      return this.$store.state.categories;
    },
  },
  methods: {
    ...mapActions([
      'addItem',
      'addItemQuantity',
      'removeItem',
      'updateItem',
      'setBox',
      'clearCart',
      'setCart',
    ]),

    IS_AGE_APPROVED() {
      this.$http.get(`${localStorage.getItem('edeka_terminal_api_url')}/cart/isAgeApproved`, {
        headers: {
          'X-Api-Key': `${localStorage.getItem('edeka_terminal_api_key')}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      })
        .then((response) => {
          if (response.data) {
            const { approved } = response.data;
            const { denied } = response.data;

            // Check if a previous interval has been set, if so clear it.
            if (this.interval !== null) {
              clearInterval(this.interval);
            }

            if (approved && !denied) {
              // close approve dialog
              this.loadingVisible = false;
            } else if (!approved && denied) {
              // close approve dialog
              this.loadingVisible = false;

              // SHOW DENIED DIALOG
              this.loadingVisibleDialog = true;
            } else {
              // show approve dialog
              this.loadingVisible = true;
              this.interval = setInterval(() => {
                this.IS_AGE_APPROVED(); // (boxID)
              }, 5000);
            }
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.errorStatus = 'Error: Network Error';
          } else {
            console.clear();

            this.errorStatus = error.response.data;
            this.loadingVisible = false;
            const serverErrorMessages = error.response.data.errors;

            serverErrorMessages.forEach((message) => {
              this.error(`${this.$t(`error.${message.code}`)}`);
            });

            // this.$router.push('/');

            // reset login error variable
            // setTimeout(() => {
            //   this.loginError = false;
            // }, 3000);

            // delete created notification dom element
            setTimeout(() => {
              if (document.querySelector('.el-notification')) {
                const elem = document.querySelector('.el-notification');
                elem.remove();
              }
            }, 3500);
          }
        });
    },

    unlockRegister(qr, id) {
      const t = this;
      const formData = new FormData();

      formData.append('token', qr);
      formData.append('registerID', id);

      this.$http.post(`${localStorage.getItem('edeka_terminal_api_url')}/cart/approveAgeVerification`, formData, {
        headers: {
          'X-Api-Key': `${localStorage.getItem('edeka_terminal_api_key')}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      })
        .then((response) => {
          if (response.data) {
            console.log(response.data);

            const { approved } = response.data;

            if (approved) {
              // close approve dialog
              this.loadingVisible = false;
            }
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.errorStatus = 'Error: Network Error';
          } else {
            console.clear();

            const serverErrorMessages = error.response.data.errors;

            serverErrorMessages.forEach((message) => {
              t.error(`${this.$t(`error.${message.code}`)}`);
            });

            // delete created notification dom element
            setTimeout(() => {
              if (document.querySelector('.el-notification')) {
                const elem = document.querySelector('.el-notification');
                elem.remove();
              }
            }, 3500);
          }
        });

      setTimeout(() => {
        // close approve dialog
        this.loadingVisible = false;
      }, 350);
    },

    async FETCH_BOX() {
      await this.setBox({
        payload: {
          token: this.boxToken,
        },
      });
      return this.$store.getters.box;
    },

    addProduct(EAN) {
      if (EAN) {
        this.addItem({
          payload: {
            ean: EAN,
            quantity: '',
          },
        });
        setTimeout(() => {
          this.scrollTo('.cart');
        }, 350);
      }
    },

    addProductQuantity(EAN, q) {
      if (EAN && q) {
        this.addItem({
          payload: {
            ean: EAN,
            quantity: q,
          },
        });
        setTimeout(() => {
          this.scrollTo('.cart');
        }, 350);
      }
    },

    removeProduct(id) {
      if (id) {
        this.removeItem(id);
      }

      // if cart empty redirect to cart (scanner)
      setTimeout(() => {
        if (this.storeCart.items.length === 0) {
          this.$router.push('/cart');
        }
      }, 350);
    },

    increase(product) {
      if (product) {
        const p = product;
        p.quantity += 1;
        this.updateItem({
          id: Number(p.id),
          quantity: p.quantity,
        });
      }
    },

    decrease(product) {
      if (product) {
        if (product.quantity > 1) {
          const p = product;
          p.quantity -= 1;
          this.updateItem({
            id: Number(p.id),
            quantity: p.quantity,
          });
        } else {
          this.removeItem(product.id);

          // if cart empty redirect to cart (scanner)
          setTimeout(() => {
            if (this.storeCart.items.length === 0) {
              this.$router.push('/cart');
            }
          }, 350);
        }
      }
    },

    scrollTo() {
      const container = this.$el;
      container.scrollTop = container.scrollHeight;
    },

    priceFormat(price) {
      let activeLang = 'de-DE';
      if (localStorage.getItem('boxLang') === 'en_US') {
        activeLang = 'en-US';
      }
      return new Intl.NumberFormat(
        activeLang,
        {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        },
      ).format(price);
    },

    done(path) {
      if (path) {
        console.log(path);
        this.$router.push(path);
      }
    },

    async resetStoreCart() {
      // reset store cart
      await this.clearCart();

      const formData = new FormData();
      /**
       * Locale
       */
      formData.append('locale', localStorage.getItem('boxLang'));
      // Logout data
      await this.$http.post(`${localStorage.getItem('edeka_terminal_api_url')}/logout`, formData, {
        headers: {
          'X-Api-Key': `${localStorage.getItem('edeka_terminal_api_key')}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      })
        .then((response) => {
          if (response.data) {
            // mutating to store for client rendering &
            // saving token in cookie for server rendering
            this.$store.commit('SET_AUTH', null);
            localStorage.removeItem('auth');

            // mutating to store for client rendering &
            // saving user data in cookie for server rendering
            this.$store.commit('SET_USER', null);
            localStorage.removeItem('user');

            // clear other important data
            localStorage.removeItem('qrcodeActive');
          }
        });

      // redirect to home + reload app
      window.location.assign('/');
    },

    openDeniedDialog() {
      this.loadingVisibleDialog = true;
    },

    closeApproveLoading() {
      this.loadingVisibleDialog = false;
      // delete cart
      this.resetStoreCart();
    },

    closeDeniedDialog() {
      this.loadingVisibleDialog = false;
      // delete cart
      this.resetStoreCart();
    },

    confirmDeniedDialog() {
      this.loadingVisibleDialog = false;
      // back to summary
      this.$router.go(-1);
    },

    keyDownHandler(e) {
      // 4001724819004 // 4001686315354 // 2704493005006
      const keyCode = e.keyCode || e.which;
      const code = e.code || '';
      let char = e.key;

      if (keyCode === 89 && code === 'KeyY' && char === 'Y') char = 'Z';
      else if (keyCode === 89 && code === 'KeyY' && char === 'y') char = 'z';
      else if (keyCode === 90 && code === 'KeyZ' && char === 'Z') char = 'Y';
      else if (keyCode === 90 && code === 'KeyZ' && char === 'z') char = 'y';

      if (char.length > 1) char = '';

      // scan articles
      this.qrCODE += char;

      if (e.keyCode === 13) {
        const qrcode = this.qrCODE;
        const registerID = localStorage.getItem('registerID');
        this.qrCODE = '';
        this.unlockRegister(qrcode, registerID);
      }
    },

    success() {
      this.$notify({
        message: this.$t('cart.product.success'),
        type: 'success',
        position: 'bottom-right',
        showClose: false,
        duration: 1500,
      });
    },

    error(message) {
      if (message) {
        this.$notify({
          message: this.$t(`${message}`),
          type: 'error',
          position: 'bottom-right',
          showClose: false,
          duration: 3000,
        });
      } else {
        this.$notify({
          message: this.$t('cart.product.failed'),
          type: 'error',
          position: 'bottom-right',
          showClose: false,
          duration: 3000,
        });
      }
    },

    scrollHandler() {
      const scrolledElement = document.documentElement;
      const { scrollTop } = scrolledElement;

      // Check if scroll state has changed significantly
      if (Math.abs(scrollTop - this.lastScrollTop) > 5) {
        // Update the last scroll position
        this.lastScrollTop = scrollTop;

        // Check if scrolled more than 124 pixels and flag is false
        if (scrollTop > 164 && !this.isScrolledMoreThan) {
          // Reverse logic here
          document.querySelector('table.table-container').classList.add('padding-top');
          document.querySelector('thead').classList.add('fixed');
          // console.log('Scrolled more than 124 pixels!');
          this.isScrolledMoreThan = true;
        }

        // Check if scrolled less than or equal to 124 pixels and flag is true
        if (scrollTop <= 164 && this.isScrolledMoreThan) {
          // Reset logic if needed
          document.querySelector('table.table-container').classList.remove('padding-top');
          document.querySelector('thead').classList.remove('fixed');
          // console.log('Scrolled less than or equal to 124 pixels.');
          this.isScrolledMoreThan = false;
        }
      }
    },
  },
  destroyed() {
    window.removeEventListener('keydown', this.keyDownHandler);
    window.removeEventListener('scroll', this.scrollHandler);
  },
};
</script>
